<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    button: { type: String, default: '' },
    features: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    openBillingPage() {
      this.$router.push({
        name: 'billing_settings_index',
        params: { accountId: this.accountId },
      });
    },
  },
};
</script>

<template>
  <div
    class="flex flex-col gap-12 sm:gap-16 items-center justify-center py-0 px-4 md:px-0 w-full h-full max-w-full overflow-auto bg-white dark:bg-slate-900"
  >
    <div class="flex flex-col justify-start sm:justify-center gap-6">
      <div class="flex flex-col gap-1.5 items-start sm:items-center">
        <h1
          class="text-slate-900 dark:text-white text-left sm:text-center text-4xl sm:text-5xl mb-6 font-semibold"
        >
          {{ $t(title) }}
        </h1>
        <p
          class="max-w-2xl text-base font-normal leading-6 text-left sm:text-center text-slate-700 dark:text-slate-200"
        >
          {{ $t(description) }}
        </p>
      </div>
      <div
        class="flex flex-row gap-3 justify-start items-center sm:justify-center"
      >
        <woot-button
          size="medium"
          color-scheme="primary"
          @click="openBillingPage"
        >
          {{ $t(button) }}
        </woot-button>
      </div>
    </div>
    <div
      class="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-16 w-full max-w-2xl overflow-auto"
    >
      <div
        v-for="feature in features"
        :key="feature.key"
        class="w-64 min-w-full"
      >
        <div class="flex gap-2 flex-row">
          <div>
            <fluent-icon
              :icon="feature.icon"
              icon-lib="fluent"
              :size="26"
              class="mt-px text-slate-800 dark:text-slate-25"
            />
          </div>
          <div>
            <h5 class="font-semibold text-lg text-slate-800 dark:text-slate-25">
              {{ $t(feature.title) }}
            </h5>
            <p class="text-sm leading-6 text-slate-700 dark:text-slate-100">
              {{ $t(feature.description) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
