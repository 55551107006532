import { frontendURL } from '../../../../helper/URLHelper';

const TeamsIndex = () => import('./Index.vue');
const CreateStepWrap = () => import('./Create/Index.vue');
const EditStepWrap = () => import('./Edit/Index.vue');
const CreateTeam = () => import('./Create/CreateTeam.vue');
const EditTeam = () => import('./Edit/EditTeam.vue');
const AddAgents = () => import('./Create/AddAgents.vue');
const EditAgents = () => import('./Edit/EditAgents.vue');
const FinishSetup = () => import('./FinishSetup.vue');
const SettingsContent = () => import('../Wrapper.vue');
const SettingsWrapper = () => import('../SettingsWrapper.vue');

const upgradeParams = {
  featureFlagKey: 'TEAM_MANAGEMENT',
  upgradeTitle: 'TEAMS_SETTINGS.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'TEAMS_SETTINGS.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'TEAMS_SETTINGS.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.ADD_MEMBERS.TITLE',
      icon: 'person-add',
      description:
        'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.ADD_MEMBERS.DESCRIPTION',
    },
    {
      key: 2,
      title: 'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.ROLE_MANAGEMENT.TITLE',
      icon: 'lock-closed',
      description:
        'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.ROLE_MANAGEMENT.DESCRIPTION',
    },
    {
      key: 3,
      title: 'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.ACTIVITY_LOGS.TITLE',
      icon: 'apps-list-detail',
      description:
        'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.ACTIVITY_LOGS.DESCRIPTION',
    },
    {
      key: 4,
      title: 'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.TEAM_ANALYTICS.TITLE',
      icon: 'chart-person',
      description:
        'TEAMS_SETTINGS.UPGRADE_PAGE.FEATURES.TEAM_ANALYTICS.DESCRIPTION',
    },
  ],
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/teams'),
      component: SettingsWrapper,
      props: {
        ...upgradeParams,
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_teams_list',
          component: TeamsIndex,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/teams'),
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'TEAMS_SETTINGS.HEADER',
          headerButtonText: 'TEAMS_SETTINGS.NEW_TEAM',
          icon: 'people-team',
          newButtonRoutes: ['settings_teams_new'],
          showBackButton: true,
        };
      },
      children: [
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'settings_teams_new',
              component: CreateTeam,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: ':teamId/finish',
              name: 'settings_teams_finish',
              component: FinishSetup,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: ':teamId/agents',
              name: 'settings_teams_add_agents',
              meta: {
                permissions: ['administrator'],
              },
              component: AddAgents,
            },
          ],
        },
        {
          path: ':teamId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_teams_edit',
              component: EditTeam,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: 'agents',
              name: 'settings_teams_edit_members',
              component: EditAgents,
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: 'finish',
              name: 'settings_teams_edit_finish',
              meta: {
                permissions: ['administrator'],
              },
              component: FinishSetup,
            },
          ],
        },
      ],
    },
  ],
};
