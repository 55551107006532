import { frontendURL } from 'dashboard/helper/URLHelper';
const InboxListView = () => import('./InboxList.vue');
const InboxDetailView = () => import('./InboxView.vue');
const InboxEmptyStateView = () => import('./InboxEmptyState.vue');
import {
  ROLES,
  CONVERSATION_PERMISSIONS,
} from 'dashboard/constants/permissions.js';

const upgradeParams = {
  featureFlagKey: 'INBOX_VIEW',
  upgradeTitle: 'INBOX.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'INBOX.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'INBOX.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'INBOX.UPGRADE_PAGE.FEATURES.UNIFIED_INBOX.TITLE',
      icon: 'mail',
      description: 'INBOX.UPGRADE_PAGE.FEATURES.UNIFIED_INBOX.DESCRIPTION',
    },
    {
      key: 2,
      title: 'INBOX.UPGRADE_PAGE.FEATURES.TEAM_COLLABORATION.TITLE',
      icon: 'people-team',
      description: 'INBOX.UPGRADE_PAGE.FEATURES.TEAM_COLLABORATION.DESCRIPTION',
    },
    {
      key: 3,
      title: 'INBOX.UPGRADE_PAGE.FEATURES.ASSIGN_CONVERSATIONS.TITLE',
      icon: 'person-arrow-right',
      description:
        'INBOX.UPGRADE_PAGE.FEATURES.ASSIGN_CONVERSATIONS.DESCRIPTION',
    },
    {
      key: 4,
      title: 'INBOX.UPGRADE_PAGE.FEATURES.CUSTOM_TAGS.TITLE',
      icon: 'tag',
      description: 'INBOX.UPGRADE_PAGE.FEATURES.CUSTOM_TAGS.DESCRIPTION',
    },
  ],
};

export const routes = [
  {
    path: frontendURL('accounts/:accountId/inbox-view'),
    component: InboxListView,
    props: {
      ...upgradeParams,
    },
    children: [
      {
        path: '',
        name: 'inbox_view',
        component: InboxEmptyStateView,
        meta: {
          permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
        },
      },
      {
        path: ':notification_id',
        name: 'inbox_view_conversation',
        component: InboxDetailView,
        meta: {
          permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
        },
      },
    ],
  },
];
