import { frontendURL } from '../../../../helper/URLHelper';
import {
  ROLES,
  CONVERSATION_PERMISSIONS,
} from 'dashboard/constants/permissions.js';
const SettingsWrapper = () => import('../SettingsWrapper.vue');
const CannedHome = () => import('./Index.vue');

const upgradeParams = {
  featureFlagKey: 'CANNED_RESPONSES',
  upgradeTitle: 'CANNED_MGMT.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'CANNED_MGMT.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'CANNED_MGMT.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'CANNED_MGMT.UPGRADE_PAGE.FEATURES.CREATE_RESPONSES.TITLE',
      icon: 'add',
      description:
        'CANNED_MGMT.UPGRADE_PAGE.FEATURES.CREATE_RESPONSES.DESCRIPTION',
    },
    {
      key: 2,
      title: 'CANNED_MGMT.UPGRADE_PAGE.FEATURES.MANAGE_RESPONSES.TITLE',
      icon: 'settings',
      description:
        'CANNED_MGMT.UPGRADE_PAGE.FEATURES.MANAGE_RESPONSES.DESCRIPTION',
    },
    {
      key: 3,
      title: 'CANNED_MGMT.UPGRADE_PAGE.FEATURES.SHARE_RESPONSES.TITLE',
      icon: 'share',
      description:
        'CANNED_MGMT.UPGRADE_PAGE.FEATURES.SHARE_RESPONSES.DESCRIPTION',
    },
    {
      key: 4,
      title: 'CANNED_MGMT.UPGRADE_PAGE.FEATURES.USE_RESPONSES.TITLE',
      icon: 'flash-auto',
      description:
        'CANNED_MGMT.UPGRADE_PAGE.FEATURES.USE_RESPONSES.DESCRIPTION',
    },
  ],
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/canned-response'),
      component: SettingsWrapper,
      props: {
        ...upgradeParams,
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'canned_list',
          meta: {
            permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
          },
          component: CannedHome,
        },
      ],
    },
  ],
};
