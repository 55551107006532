import { frontendURL } from '../../../../helper/URLHelper';

const SettingsWrapper = () => import('../SettingsWrapper.vue');
const AuditLogsHome = () => import('./Index.vue');

const upgradeParams = {
  featureFlagKey: 'AUDIT_LOGS',
  upgradeTitle: 'AUDIT_LOGS.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'AUDIT_LOGS.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'AUDIT_LOGS.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.ACTIVITY_MONITORING.TITLE',
      icon: 'eye-tracking-on',
      description:
        'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.ACTIVITY_MONITORING.DESCRIPTION',
    },
    {
      key: 2,
      title: 'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.SECURITY_LOGS.TITLE',
      icon: 'shield',
      description: 'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.SECURITY_LOGS.DESCRIPTION',
    },
    {
      key: 3,
      title: 'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.COMPLIANCE.TITLE',
      icon: 'clipboard-task',
      description: 'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.COMPLIANCE.DESCRIPTION',
    },
    {
      key: 4,
      title: 'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.EXPORT_LOGS.TITLE',
      icon: 'arrow-download',
      description: 'AUDIT_LOGS.UPGRADE_PAGE.FEATURES.EXPORT_LOGS.DESCRIPTION',
    },
  ],
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/audit-logs'),
      component: SettingsWrapper,
      props: {
        ...upgradeParams,
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'auditlogs_list',
          meta: {
            permissions: ['administrator'],
          },
          component: AuditLogsHome,
        },
      ],
    },
  ],
};
