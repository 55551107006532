import { frontendURL } from 'dashboard/helper/URLHelper';

import {
  ROLES,
  CONVERSATION_PERMISSIONS,
} from 'dashboard/constants/permissions.js';
const SettingsContent = () => import('../Wrapper.vue');
const SettingsWrapper = () => import('../SettingsWrapper.vue');
const Macros = () => import('./Index.vue');
const MacroEditor = () => import('./MacroEditor.vue');

const upgradeParams = {
  featureFlagKey: 'MACROS',
  upgradeTitle: 'MACROS.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'MACROS.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'MACROS.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'MACROS.UPGRADE_PAGE.FEATURES.CREATE_MACROS.TITLE',
      icon: 'add',
      description: 'MACROS.UPGRADE_PAGE.FEATURES.CREATE_MACROS.DESCRIPTION',
    },
    {
      key: 2,
      title: 'MACROS.UPGRADE_PAGE.FEATURES.MANAGE_MACROS.TITLE',
      icon: 'settings',
      description: 'MACROS.UPGRADE_PAGE.FEATURES.MANAGE_MACROS.DESCRIPTION',
    },
    {
      key: 3,
      title: 'MACROS.UPGRADE_PAGE.FEATURES.SHARE_MACROS.TITLE',
      icon: 'people',
      description: 'MACROS.UPGRADE_PAGE.FEATURES.SHARE_MACROS.DESCRIPTION',
    },
    {
      key: 4,
      title: 'MACROS.UPGRADE_PAGE.FEATURES.AUTOMATE_TASKS.TITLE',
      icon: 'arrow-right',
      description: 'MACROS.UPGRADE_PAGE.FEATURES.AUTOMATE_TASKS.DESCRIPTION',
    },
  ],
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/macros'),
      component: SettingsWrapper,
      props: {
        ...upgradeParams,
      },
      children: [
        {
          path: '',
          name: 'macros_wrapper',
          component: Macros,
          meta: {
            permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/macros'),
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'MACROS.HEADER',
          icon: 'flash-settings',
          showBackButton: true,
        };
      },
      children: [
        {
          path: ':macroId/edit',
          name: 'macros_edit',
          component: MacroEditor,
          meta: {
            permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
          },
        },
        {
          path: 'new',
          name: 'macros_new',
          component: MacroEditor,
          meta: {
            permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
          },
        },
      ],
    },
  ],
};
