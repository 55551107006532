import { frontendURL } from '../../../../helper/URLHelper';
const SettingsWrapper = () => import('../SettingsWrapper.vue');
const Automation = () => import('./Index.vue');

const upgradeParams = {
  featureFlagKey: 'AUTOMATIONS',
  upgradeTitle: 'AUTOMATION.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'AUTOMATION.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'AUTOMATION.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'AUTOMATION.UPGRADE_PAGE.FEATURES.RULES.TITLE',
      icon: 'clipboard-task-list-rtl',
      description: 'AUTOMATION.UPGRADE_PAGE.FEATURES.RULES.DESCRIPTION',
    },
    {
      key: 2,
      title: 'AUTOMATION.UPGRADE_PAGE.FEATURES.TRIGGERS.TITLE',
      icon: 'flash-on',
      description: 'AUTOMATION.UPGRADE_PAGE.FEATURES.TRIGGERS.DESCRIPTION',
    },
    {
      key: 3,
      title: 'AUTOMATION.UPGRADE_PAGE.FEATURES.WORKFLOWS.TITLE',
      icon: 'diagram',
      description: 'AUTOMATION.UPGRADE_PAGE.FEATURES.WORKFLOWS.DESCRIPTION',
    },
    {
      key: 4,
      title: 'AUTOMATION.UPGRADE_PAGE.FEATURES.SCHEDULERS.TITLE',
      icon: 'clock',
      description: 'AUTOMATION.UPGRADE_PAGE.FEATURES.SCHEDULERS.DESCRIPTION',
    },
  ],
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/automation'),
      component: SettingsWrapper,
      props: {
        ...upgradeParams,
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'automation_list',
          component: Automation,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
