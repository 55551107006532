import { frontendURL } from '../../../../helper/URLHelper';
const SettingsWrapper = () => import('../SettingsWrapper.vue');
const IntegrationHooks = () => import('./IntegrationHooks.vue');
const Index = () => import('./Index.vue');
const Webhook = () => import('./Webhooks/Index.vue');
const DashboardApps = () => import('./DashboardApps/Index.vue');
const Slack = () => import('./Slack.vue');
const SettingsContent = () => import('../Wrapper.vue');

const upgradeParams = {
  featureFlagKey: 'INTEGRATIONS',
  upgradeTitle: 'INTEGRATION_SETTINGS.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'INTEGRATION_SETTINGS.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'INTEGRATION_SETTINGS.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.SLACK.TITLE',
      icon: 'chat',
      description:
        'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.SLACK.DESCRIPTION',
    },
    {
      key: 2,
      title: 'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.WEBHOOKS.TITLE',
      icon: 'code',
      description:
        'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.WEBHOOKS.DESCRIPTION',
    },
    {
      key: 3,
      title: 'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.DASHBOARD_APPS.TITLE',
      icon: 'apps',
      description:
        'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.DASHBOARD_APPS.DESCRIPTION',
    },
    {
      key: 4,
      title:
        'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.EASY_INTEGRATION.TITLE',
      icon: 'plug-disconnected',
      description:
        'INTEGRATION_SETTINGS.UPGRADE_PAGE.FEATURES.EASY_INTEGRATION.DESCRIPTION',
    },
  ],
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/integrations'),
      component: SettingsWrapper,
      props: {
        ...upgradeParams,
      },
      children: [
        {
          path: '',
          name: 'settings_applications',
          component: Index,
          meta: {
            permissions: ['administrator'],
          },
        },
        {
          path: 'dashboard_apps',
          component: DashboardApps,
          name: 'settings_integrations_dashboard_apps',
          meta: {
            permissions: ['administrator'],
          },
        },
        {
          path: 'webhook',
          component: Webhook,
          name: 'settings_integrations_webhook',
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/integrations'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_integrations';
        const backUrl =
          params.name === 'settings_integrations_integration'
            ? { name: 'settings_integrations' }
            : '';
        return {
          headerTitle: 'INTEGRATION_SETTINGS.HEADER',
          icon: 'flash-on',
          showBackButton,
          backUrl,
          ...upgradeParams,
        };
      },
      children: [
        {
          path: 'slack',
          name: 'settings_integrations_slack',
          component: Slack,
          meta: {
            permissions: ['administrator'],
          },
          props: route => ({ code: route.query.code }),
        },
        {
          path: ':integration_id',
          name: 'settings_applications_integration',
          component: IntegrationHooks,
          meta: {
            permissions: ['administrator'],
          },
          props: route => ({
            integrationId: route.params.integration_id,
          }),
        },
      ],
    },
  ],
};
