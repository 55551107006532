import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

const upgradeParams = {
  featureFlagKey: 'CAMPAIGNS',
  upgradeTitle: 'CAMPAIGN.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'CAMPAIGN.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'CAMPAIGN.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'CAMPAIGN.UPGRADE_PAGE.FEATURES.CREATE_CAMPAIGNS.TITLE',
      icon: 'add',
      description:
        'CAMPAIGN.UPGRADE_PAGE.FEATURES.CREATE_CAMPAIGNS.DESCRIPTION',
    },
    {
      key: 2,
      title: 'CAMPAIGN.UPGRADE_PAGE.FEATURES.MANAGE_CAMPAIGNS.TITLE',
      icon: 'settings',
      description:
        'CAMPAIGN.UPGRADE_PAGE.FEATURES.MANAGE_CAMPAIGNS.DESCRIPTION',
    },
    {
      key: 3,
      title: 'CAMPAIGN.UPGRADE_PAGE.FEATURES.SCHEDULE_CAMPAIGNS.TITLE',
      icon: 'calendar-clock',
      description:
        'CAMPAIGN.UPGRADE_PAGE.FEATURES.SCHEDULE_CAMPAIGNS.DESCRIPTION',
    },
    {
      key: 4,
      title: 'CAMPAIGN.UPGRADE_PAGE.FEATURES.ANALYZE_CAMPAIGNS.TITLE',
      icon: 'data-pie',
      description:
        'CAMPAIGN.UPGRADE_PAGE.FEATURES.ANALYZE_CAMPAIGNS.DESCRIPTION',
    },
  ],
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'arrow-swap',
        ...upgradeParams,
      },
      children: [
        {
          path: '',
          redirect: 'ongoing',
        },
        {
          path: 'ongoing',
          name: 'ongoing_campaigns',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
        icon: 'sound-source',
        ...upgradeParams,
      },
      children: [
        {
          path: 'one_off',
          name: 'one_off',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
  ],
};
