<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import router from '../../../../index';
import { loadScript } from 'dashboard/helper/DOMHelpers';
import * as Sentry from "@sentry/browser";
import { useInstallationName } from "../../../../../../shared/mixins/globalConfigMixin";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      apiKey: '',
      phoneNumberId: '',
      businessAccountId: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
  },
  mounted() {
    window.fbAsyncInit = this.runFBInit;
  },
  methods: {
    useInstallationName,
    async startLogin() {
      useAlert(this.$t('INBOX_MGMT.DETAILS.LOADING_FB'));

      try {
        await this.loadFBsdk();

        this.$store.dispatch(
          'inboxes/handleWhatsappLogin',
          this.accountId
        ).then((loginData) => {
          this.apiKey = loginData['apiKey'];
          this.inboxName = loginData['inboxName'];
          this.phoneNumber = loginData['phoneNumber'];
          this.phoneNumberId = loginData['phoneNumberId'];
          this.businessAccountId = loginData['businessAccountId'];

          this.createChannel();
        }).catch((error) => {
          useAlert(this.$t(error));
        });
      } catch (error) {
        if (error.name === 'ScriptLoaderError') {
          useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_LOADING'));
        } else {
          Sentry.captureException(error);

          useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
        }
      }
    },
    async loadFBsdk() {
      await loadScript('https://connect.facebook.net/en_US/sdk.js', {
        id: 'facebook-jssdk',
      });

      FB.init({
        appId: window.chatwootConfig.fbAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: window.chatwootConfig.fbApiVersion,
      });

      window.fbSDKLoaded = true;
      FB.AppEvents.logPageView();
    },
    async createChannel() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'whatsapp',
              phone_number: this.phoneNumber,
              provider: 'whatsapp_embedded',
              provider_config: {
                api_key: this.apiKey,
                phone_number_id: this.phoneNumberId,
                business_account_id: this.businessAccountId,
              },
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        useAlert(
          error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-col items-center justify-center py-10 w-[65%] text-center">
    <woot-button
      :is-loading="uiFlags.isWhatsappSigning"
      class-names="!bg-[#1877f2] hover:!bg-[#0b5eca]"
      @click="startLogin"
    >
      {{ $t('INBOX_MGMT.ADD.WHATSAPP.EMBEDDED_SIGNUP.BUTTON') }}
    </woot-button>

    <div class="w-[400px] max-w-full py-6">
      <p class="text-xs text-slate-500">
        {{
          useInstallationName(
            $t('INBOX_MGMT.ADD.WHATSAPP.EMBEDDED_SIGNUP.HELP'),
            globalConfig.installationName
          )
        }}
      </p>
    </div>
  </div>
</template>
